<template>
    <r-e-dialog title="开锁记录" :visible.sync="dialogVisible" top="5vh" width="700px" :show-footer="false">
      <div @click.stop="pickerclick">

        <div class="flex" style="background-color: #EFF9FF;height: 40px;justify-content: space-between;align-items: center;color: #333333;font-size: 15px;">
          <div style="margin-left: 20px">
            房间号 : {{equipmentData.name}}
          </div>
          <div style="margin-right: 20px">
            门锁号: {{equipmentData.lockId}}
          </div>
        </div>
        <div>
          <el-select size="small" v-model="type" placeholder="请选择" v-if="iotTag===0">
            <el-option label="全部" value=""/>
            <el-option label="密码开门" value="EVENT_PWD_ACTION"/>
            <el-option label="钥匙开门" value="EVENT_KEY_ACTION"/>
            <el-option label="临时密码开门" value="EVENT_TOKEN_ACTION"/>
            <el-option label="内把手开门" value="EVENT_INNER_HANDLE_ACTION"/>
            <el-option label="关门" value="EVENT_CLOSE"/>
          </el-select>
          <el-date-picker  @change="pickerclickitr"   ref="dateArrRef" v-model="dateArr" type="daterange" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                          size="small" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"/>
          <el-button type="primary" size="small" @click="handleSearch">查询</el-button>
          <!-- 2024/01/31 张晓瑜新增重置 -->
          <el-button @click="handleReset" size="small">重置</el-button>
        </div>
        <r-e-table ref="communityTableRef" :columns="tableColumnUnlockRecord" :pretreat="pretreat" :dataRequest="getFamilyList" :query="formSearch" :height="300">
          <template slot="empty">
            <el-empty/>
          </template>
        </r-e-table>
      </div>

    </r-e-dialog>
</template>

<script>
    import {tableColumnUnlockRecord} from "@/views/property-management/door-management/data";
    import {openLocksLog} from "@/api/door-management"
    // import {openLocksLog} from "@/api/door-management"
    export default {
        name: "dialog-show-unlock-record",
        data() {
            return {
                itr:false,
                dialogVisible: false,
                dataList: [],
                tableColumnUnlockRecord,
                type: '',
                uuid: '',
                dateArr: [],
                formSearch: {},
              iotTag: null,
            }
        },
        props: {
            equipmentData: {
                type: Object,
                default: () => ({})
            }
        },
        components: {},
        methods: {
          pickerclick(){
            this.itr = !this.itr;
            if(this.itr){
              this.$refs['dateArrRef'].hidePicker();
            }
            console.log(this.itr)
          },
          pickerclickitr(){
            this.itr = true;
            console.log(this.itr)
          },
          handleReset(){
            this.$refs['dateArrRef'].hidePicker();
            this.dateArr = []
            this.handleSearch()
          },
          openDialog(data, iotTag) {
            // alert(iotTag)
            // 2023-11-4-陈守亮-修复门锁日志
            this.iotTag = Number(iotTag);
            this.uuid = data;
            this.dialogVisible = true;
          },
            getFamilyList(params) {
                let {uuid} = this;
                params.type = this.type;
                if (this.dateArr && this.dateArr.length !== 0) {
                    let [startDate, endDate] = this.dateArr;
                    params.startDate = startDate;
                    params.endDate = endDate;
                }
              console.log(openLocksLog(uuid, params))
                return openLocksLog(uuid, params);
            },
            handleSearch() {
                this.$refs["communityTableRef"].pNumber = 1;
                this.$refs["communityTableRef"].getTableData();
            },
          pretreat(list) {
            if (this.iotTag === 0) {
              list = list.map(item => {
                const {time, typeDesc} = item;
                return {...item, time, typeDesc}
              });
            }
            if (this.iotTag === 1) {
              list = list.map(item => {
                const {updateTime, logAlert: typeDesc} = item;
                const time = updateTime * 1000;
                console.log('time',time,typeDesc);
                return {...item, time, typeDesc}
              });
            }
            if (this.iotTag === 2) {
              list = list.map(item => {
                const {operateTime, remark: typeDesc} = item;
                const time = Number(operateTime);
                return {...item, time, typeDesc}
              });
            }
            return list;
          }
        },
    }
</script>

<style scoped>

</style>